String.prototype.sprintfmt												=function(){
	"use strict";
	var str=this.toString();
	if(arguments.length){
		var t=typeof arguments[0];
		var key;
		var args=(
			("string"===t||"number"===t)?
			Array.prototype.slice.call(arguments):
			arguments[0]
		);
		for(key in args)str=str.replace(new RegExp("\\{\\{" + key + "\\}\\}","gi"),args[key]);
	}
	return str;
};
$(function(){
	Handlebars.registerHelper('ifInline',function(a,b=null,c=null){
		return (a?b:c);
	});
	Handlebars.registerHelper('ifEquals',function(a,b=null,opt){
		return ((a==b)?opt.fn(this):opt.inverse(this));
	});
	Handlebars.registerHelper('decode',function(str_enc){
		const str_dec=he.decode(str_enc);
		return new Handlebars.SafeString(str_dec);
	});

	$.ajaxPrefilter(function(options,originalOptions,jqXHR){
		if(!options.crossDomain){
			let token=$('meta[name="csrf-token"]').attr('content');
			if(token)
				return jqXHR.setRequestHeader('X-CSRF-Token',token);
		}
	});
	window.binbo																		=(window.binbo??{});
	window.binbo.dt																	=(window.binbo.dt??{});
	window.binbo.dt.init														=(window.binbo.dt.init??{});
	window.binbo.dt.init.def												=(window.binbo.dt.init.def??{});
	window.binbo.dt.suf															=(window.binbo.dt.suf??{});
	window.binbo.dt.suf.langLen											=(' items');
	// window.binbo.dt.init.langURL										=(window.binbo.dt.init.langURL??("/js/datatables/i18n/bg.json"));
	window.binbo.dt.init.langOpt										={
		'url':window.binbo.dt.init.langURL,
		'searchPlaceholder':"",
		"info": "_START_ - _END_ (_TOTAL_)",
		"infoEmpty": "0 - 0 (0)",
	};
	window.binbo.dt.init.field											=(window.binbo.dt.init.field??{});
	window.binbo.dt.init.field.bool_clickable			=("<a href=\"{{uri}}\" title=\"{{title}}\"><span class=\"col-bool-sign {{yn}}\"><i class=\"fas fa-{{fa}}-circle\"></i></span></a>");
	window.binbo.dt.init.menuLen										=([
			[10,25,50,100],
			["10"+window.binbo.dt.suf.langLen,"25"+window.binbo.dt.suf.langLen,"50"+window.binbo.dt.suf.langLen,"100"+window.binbo.dt.suf.langLen],
		]);
	window.binbo.dt.fn															=(window.binbo.dt.fn??{});
	window.binbo.dt.fn.colActFnCall									=function(fn,el,ev,o){
		let data=$(el).closest('.dataTables_wrapper').find('.dataTable').DataTable().init().fn;
		if(typeof data[fn]=='function')data[fn](el,ev,o);
	};
	window.binbo.dt.init.opt												=(window.binbo.dt.init.opt??{});
	window.binbo.dt.init.opt.fn											=(window.binbo.dt.init.opt.fn??{});
	window.binbo.dt.init.opt.pagingType							=("full_numbers");
	window.binbo.dt.init.opt.bLengthChange					=(true);
	window.binbo.dt.init.opt.lengthMenu							=(window.binbo.dt.init.menuLen);
	window.binbo.dt.init.opt.pageLength							=(25);
	window.binbo.dt.init.opt.stateSave							=(true);
	window.binbo.dt.init.opt.stateDuration					=((60*60*24*7));
	window.binbo.dt.init.opt.autoWidth							=(false);
	window.binbo.dt.init.opt.dom										=("<'dt-head py-2'<'dt-push'><'dt-cap-field dt-head-btn dt-btn-reload btn'<'fas fa-redo-alt'>><'dt-cap-field'l><'dt-caption'<>><'dt-head-right'<'dt-X-head-btn h-100'><'dt-cap-field'f>>><'dt-X-row-create'><'minw-100p dt-table-holder'tr><'row dt-foot py-1'<'col-md-5'i><'col-md-7'p>>");
	window.binbo.dt.init.opt.language								=(window.binbo.dt.init.langOpt);
	window.binbo.dt.init.opt.serverSide							=(true);
	window.binbo.dt.init.opt.ajax										=("");
	window.binbo.dt.init.opt.order									=[[0,'desc']];
	window.binbo.dt.init.opt.columnDefs							=[
																										{
																											targets:"act",
																											className:"act dt-center"
																										},
																										{
																											targets:"_all",
																											className:"dt-center"
																										}
																									];
	window.binbo.dt.init.opt.columns								=[];
	$.fn.dataTable.render.ellipsis									=function(cutoff){
		return function(data,type,row){
			return (
				((type==='display')&&(data!==null)&&(data.length>cutoff))?
				(data.substr(0,cutoff)+'…'):
				(data)
			);
		}
	};

	window.binbo.dt.init.opt.fn.preInit								=function(ev){
		let 
			dtnode=$(ev.target),
			wrnode=dtnode.closest(".dataTables_wrapper");
		wrnode.addClass('pre_loading loading');
		dtnode
			.on('processing.dt',function(){
				$o=$(this);
				wrnode.addClass('pre_loading');
				wrnode.addClass('loading');
			})
			.on('xhr.dt',function(){
				$o=$(this);
				wrnode.removeClass('loading');
				setTimeout(
					function(){
						wrnode.removeClass('pre_loading').addClass('inited');
					},
					330
				);
			});
		wrnode
			.find(".dt-btn-reload")
			.first()
			.replaceWith(function(){
				var
					attributes=
						$(this)
							.prop("attributes"),
					$newEl=
						$('<button>')
							.on("click.dtreload",function(ev){
								$newEl.find('svg animateTransform')[0].beginElement();
								dtnode.DataTable().ajax.reload(function(json){
									setTimeout(
										function(){
											$newEl.find('svg animateTransform')[0].endElement();
										},
										330
									);
								},false);
							});
				// dtnode.find('tbody').first().append($('<tr>').append($('<td>').attr('colspan',dtnode.DataTable().columns()[0].length)).addClass('loading'));
				$.get('/img/loadbar-spinner-static.svg',function(svg){
					$newEl.html(svg);
				},'text');
				$.each(attributes,function(){
					$newEl.attr(this.name,this.value);
				});
				return $newEl;
			});
		wrnode
			.find(".dataTables_length select.form-control,.dataTables_filter input.form-control").addClass("text-sm rounded-lg focus:ring-primary-600 dark:placeholder-gray-400 py-2.5");
		wrnode
			.find(".dataTables_length,.dataTables_filter").each(function(i){
				let
					pobj=$(this),
					cobj=$(pobj.children().contents());
				cobj
					.filter(function(){
						return (this.nodeType==Node.TEXT_NODE);
					})
					.remove();
			});
		let dtTitleNode=wrnode.closest(':has(".dt-pre-compact-title")').find('.dt-pre-compact-title');
		if(dtTitleNode.length)wrnode.find(".dt-caption>div").html(dtTitleNode.html());
		if(dtnode.hasClass('auto-refresh')){
			let html_code=(`
<div class="dt-btn-autorefresh">
	<label class="relative inline-flex items-center cursor-pointer">
		<input type="checkbox" class="sr-only peer">
		<div class="w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-slate-950 peer-checked:bg-primary-600"></div>
		<span class="refresher-text ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Refresh</span>
	</label>
</div>
				`);
			$(html_code).insertAfter(wrnode.find(".dt-head .dt-btn-reload+.dt-cap-field"));
			wrnode.find(".dt-head .dt-btn-autorefresh input").first().on("change.dt-autorefresh",function(ev){
				dtnode[0].auto_refresh=$(ev.target).is(':checked');
				$refresher=wrnode
					.find(".dt-head .dt-btn-autorefresh .refresher-text")
					.on('update.countdown',function(ev){
						$(this).html("Refreshing in "+ev.strftime('%M:%S'));
					});
				if(dtnode[0].auto_refresh){
					$refresher
						.countdown(((new Date()).valueOf())+180000);
					dtnode[0].auto_refresh_timer=setInterval(function(){
						console.log("Refreshing...");
						wrnode.children('.dt-head').find('.dt-btn-reload').trigger('click.dtreload');
						$refresher
							.countdown(((new Date()).valueOf())+180000);
						if(!dtnode[0].auto_refresh)clearInterval(dtnode[0].auto_refresh_timer);
					},180000);
				}
				else{
					$refresher
						.countdown(1)
						.html('Refresh');
					clearInterval(dtnode[0].auto_refresh_timer);
				}
			});
		}
	};
	$('.dt-simplify')
		.one("preInit.dt",window.binbo.dt.init.opt.fn.preInit)
		.one("draw.dt",window.binbo.dt.init.opt.fn.onDraw);

	$('.dataTable:not(.skip-auto)').DataTable({
		// bLengthChange:false,
		// dom:window.binbo.dt.init.opt.dom,
		// language:window.binbo.dt.init.langOpt,
	});
});
