/* Dependencies */
try{
	// jQuery
	window.$=window.jQuery=require('jquery');
	window.he=require('he');
	require('form-serializer');
	require('jquery-countdown');
	// jQueryUI
	require('jquery-ui');
	require('jquery-ui/ui/widget.js');
	require('jquery-ui/ui/widgets/mouse.js');
	require('jquery-ui/ui/widgets/sortable.js');
	require('jquery-ui/ui/widgets/draggable.js');
	require('jquery-ui/ui/widgets/droppable.js');
	// Bootstrap
	// window.bootstrap=require('bootstrap');
	// Selectize
	require('@selectize/selectize');
	// require('./partials/selectize.plugin.readonly');
	// Datatables
	require('datatables.net-bs')(window,$);


}
catch(e){}
/* App */
import	* as bootstrap										from		'bootstrap';
import	Handlebars												from		'handlebars/dist/handlebars.js';
// import template from './template.handlebars';
window.Bootstrap=bootstrap;
window.Handlebars=Handlebars;
try{
	require('./main');
}
catch(e){}

